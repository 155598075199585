<template>
  <v-container fluid>
    <div class="d-flex mb-6">
      {{ produto.id }} - {{ produto.descricao }} - Ref.
      {{ produto.referencia }}
      <v-spacer></v-spacer>
      <v-btn @click="updateProduto" color="button_1" class="white--text">
        <v-icon left>mdi-check</v-icon>
        {{ $tc("global.salvar") }}
      </v-btn>
    </div>
    <v-row class="mb-6" dense>
      <v-col cols="12" md="2">
        <v-card outlined class="fill-height d-flex align-center justify-center">
          <v-img v-if="img" :src="img" contain></v-img>
          <v-img
            v-else
            :src="require('@/assets/pagina-default.png')"
            contain
          ></v-img>
          <v-btn
            :disabled="disableCamposProdutos"
            fab
            dark
            x-small
            absolute
            bottom
            right
            @click="onButtonClick"
          >
            <input
              accept="image/*"
              type="file"
              class="d-none"
              ref="uploader"
              @change="onFileChanged"
            />
            <v-icon>mdi-image</v-icon>
          </v-btn>
        </v-card>
      </v-col>
      <v-col cols="12" md="10">
        <v-row dense>
          <!-- Descrição -->
          <v-col cols="12" md="3">
            <v-text-field
              v-model="produto.descricao"
              :label="this.$tc('global.descricao')"
              outlined
              :disabled="disableCamposProdutos"
              dense
            ></v-text-field>
          </v-col>
          <!-- Classificações -->
          <v-col cols="12" md="2">
            <v-select
              v-model="produto.produto_categoria_id"
              :items="categorias"
              :loading="loadingClass"
              clearable
              item-text="descricao"
              item-value="id"
              dense
              outlined
              :label="this.$tc('global.categoria')"
              :disabled="disableCamposProdutos"
            ></v-select>
          </v-col>
          <!-- Referencia -->
          <v-col cols="12" md="2">
            <v-text-field
              v-model="produto.referencia"
              :label="this.$tc('global.referencia')"
              outlined
              :disabled="disableCamposProdutos"
              dense
            ></v-text-field>
          </v-col>
          <!-- Controle de estoque -->
          <v-col cols="12" md="3">
            <v-card
              outlined
              height="40px"
              class="pa-3 d-flex justify-space-between align-center"
            >
              <v-subheader
                style="height: auto !important"
                class="ma-0 pa-0 body-1"
              >
                {{ $tc("global.controleEstoque") }}
              </v-subheader>
              <v-switch
                :false-value="0"
                :true-value="1"
                :disabled="disableCamposProdutos"
                v-model="produto.controle_estoque"
              ></v-switch>
            </v-card>
          </v-col>

          <v-col cols="12" md="2" class="pb-6">
            <v-card
              outlined
              height="40px"
              class="pa-3 d-flex justify-space-between align-center"
            >
              <v-subheader
                style="height: auto !important"
                class="ma-0 pa-0 body-1"
              >
                Status
                <span class="pl-1" v-if="produto.ativo">
                  {{ $tc("global.ativo") }}
                </span>
                <span class="pl-1" v-else>{{ $tc("global.inativo") }}</span>
              </v-subheader>
              <v-switch
                :false-value="false"
                :true-value="true"
                v-model="produto.ativo"
              ></v-switch>
            </v-card>
          </v-col>
          <!-- Url -->
          <v-col cols="12" md="3">
            <v-text-field
              v-model="produto.url"
              label="Url"
              :disabled="disableCamposProdutos"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <!-- Moeda Custo -->
          <v-col cols="12" md="2">
            <v-select
              v-model="selectedMoeda"
              :items="moedas"
              clearable
              :readonly="disableCamposProdutos"
              dense
              outlined
              item-text="sigla"
              item-value="id"
              :label="this.$tc('global.moeda') + ' ' + this.$tc('global.custo')"
              :disabled="moeda_empresa ? true : false"
              return-object
            ></v-select>
          </v-col>

          <!-- Fornecedores -->
          <v-col cols="12" md="4">
            <v-combobox
              v-model="selectedFornecedor"
              :label="$tc('global.fornecedor')"
              :items="fornecedores"
              :loading="loadingClass"
              :disabled="disableCamposProdutos"
              item-text="nome"
              item-value="id"
              dense
              outlined
              clearable
              hide-details
              class="mb-2"
              required
              return-object
              :rules="formRules"
            ></v-combobox>
          </v-col>

          <!-- marcas -->
          <v-col cols="12" md="3">
            <v-select
              v-model="selectedMarca"
              :items="marcas"
              :disabled="disableCamposProdutos"
              :loading="loadingClass"
              item-text="nome"
              item-value="id"
              dense
              outlined
              return-object
              :label="$tc('global.marca', 2)"
            ></v-select>
          </v-col>

          <!-- Estoque Min -->
          <v-col cols="12" md="2">
            <v-text-field
              v-model="produto.estoque_min"
              :disabled="disableCamposProdutos"
              :label="this.$tc('global.produto') + ' Min'"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <!-- Estoque Max -->
          <v-col cols="12" md="2">
            <v-text-field
              v-model="produto.estoque_max"
              :disabled="disableCamposProdutos"
              :label="this.$tc('global.produto') + ' Max'"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <!-- Qtde por Caixa -->
          <v-col cols="12" md="2">
            <v-text-field
              v-model="produto.qtde_caixa"
              :disabled="disableCamposProdutos"
              :label="this.$tc('global.qtdcaixa')"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <!-- Unidade de Medida -->
          <v-col cols="12" md="2">
            <v-select
              :disabled="disableCamposProdutos"
              v-model="produto.und"
              :items="unidades"
              clearable
              dense
              outlined
              :label="this.$tc('global.unidademed')"
            ></v-select>
          </v-col>
          <!-- tipo imposto -->
          <v-col cols="12" md="4">
            <v-select
              v-model="produto.tipo_imposto"
              :disabled="disableCamposProdutos"
              :items="tipo_imposto"
              clearable
              dense
              item-text="descricao"
              item-value="value"
              outlined
              :label="$tc('global.tipo') + ' ' + $tc('global.imposto')"
            ></v-select>
          </v-col>
          <!-- Descrição detalhada -->
          <v-col cols="12">
            <v-textarea
              v-model="produto.descricao_detalhada"
              :disabled="disableCamposProdutos"
              :label="this.$tc('global.descricaodetalhada')"
              :rows="4"
              outlined
              dense
              hide-details
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getAllProClassAtivo, putProdutos } from "@/api/produtos/produtos.js";

import { mapGetters, mapState } from "vuex";
// import { fetchMoedas } from "@/api/geral/moedas.js";
import { inputRequired } from "@/helpers/utils/";
import { fetchMarcas } from "@/api/marcas/marcas.js";
import { getFornecedores } from "@/api/fornecedores/fornecedores.js";

export default {
  name: "ProdutoCadastro",

  props: {
    item: {
      type: Object,
      required: true,
    },
    getProduto: {
      type: Function,
    },
  },

  data() {
    return {
      loading: false,
      categorias: [],
      fornecedores: [],
      selectedFornecedor: null,
      selectedMarca: null,
      formRules: [inputRequired],
      loadingClass: false,
      selectedMoeda: null,
      img: null,
      imgUpload: null,
      marcas: [],
      unidades: [
        {
          text: this.$tc("global.metros"),
          value: "MTS",
        },
        {
          text: this.$tc("global.unidade", 2),
          value: "UND",
        },
        {
          text: "KG",
          value: "KGS",
        },
      ],

      produto: {},
      produtoOriginal: {},
      tipo_imposto: [
        {
          descricao: "ISENTO",
          value: "ISENTO",
        },
        {
          descricao: "GRAVADO 10 %",
          value: "GRAVADO_10",
        },
        {
          descricao: "GRAVADO 5 %",
          value: "GRAVADO_5",
        },
        {
          descricao: "DECRETO",
          value: "DECRETO",
        },
      ],
    };
  },
  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    ...mapState("Usuario", {
      moeda_empresa: (state) => state.empresa.moeda_empresa,
    }),

    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda.sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },

    disableCamposProdutos() {
      let result = false;
      if (!this.produtoOriginal.ativo) {
        result = true;
      }
      return result;
    },
  },

  watch: {
    selectedFornecedor() {
      if (this.selectedFornecedor) {
        this.produto.fornecedor_id = this.selectedFornecedor.id;
      }
    },
    selectedMarca() {
      if (this.selectedMarca) {
        this.produto.marca_id = this.selectedMarca.id;
      }
    },
  },

  methods: {
    getMarcas() {
      this.loading = true;
      this.marcas = [];
      return fetchMarcas()
        .then((response) => {
          this.marcas = response;
          this.selectedMarca = this.marcas.find(
            (marca) => marca.id === this.produto.marca_id
          );
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    onButtonClick() {
      this.$refs.uploader.click();
    },

    onFileChanged(e) {
      const file = e.target.files[0];
      this.imgUpload = file;
      this.produto.img = !this.produto.img ? "img" : null;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.img = event.target.result;
        // vue.editFornecedor();
      };
    },

    async fetchFornecedores() {
      this.loadingClass = true;
      await getFornecedores()
        .then((response) => {
          this.fornecedores = response;
          this.loadingClass = false;
          this.selectedFornecedor = this.fornecedores.find(
            (fornecedor) => fornecedor.id === this.produto.fornecedor_id
          );
        })
        .catch((error) => console.log(error));
    },

    getProClass() {
      this.loadingClass = true;
      return getAllProClassAtivo()
        .then((response) => {
          this.categorias = response;
          this.loadingClass = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingClass = false;
        });
    },

    updateProduto() {
      const produto = new FormData();

      if (this.imgUpload) {
        produto.append("img", this.imgUpload, this.imgUpload.name);
        // produto.append("img64", this.img);
      }

      let produto_2 = {};
      produto_2.empresa_id = this.produto.empresa_id;
      produto_2.produto_categoria_id = this.produto.produto_categoria_id;
      produto_2.descricao = this.produto.descricao;
      produto_2.descricao_detalhada = this.produto.descricao_detalhada;
      produto_2.referencia = this.produto.referencia;
      produto_2.fornecedor_id = this.produto.fornecedor_id;
      produto_2.marca_id = this.produto.marca_id;
      produto_2.custo_moeda_id = this.produto.custo_moeda_id;
      produto_2.und = this.produto.und;
      produto_2.qtde_caixa = this.produto.qtde_caixa;
      produto_2.estoque_min = this.produto.estoque_min;
      produto_2.estoque_max = this.produto.estoque_max;
      produto_2.url = this.produto.url;
      produto_2.controle_estoque = this.produto.controle_estoque;
      produto_2.tipo_imposto = this.produto.tipo_imposto;
      produto_2.ativo = this.produto.ativo;

      for (let key in produto_2) {
        if (
          produto_2[key] !== null &&
          produto_2[key] !== undefined &&
          produto_2[key] !== ""
        ) {
          produto.append(key, produto_2[key]);
        }
      }

      putProdutos(this.produto.id, produto)
        .then(() => {
          this.getProduto();
        })
        .catch((error) => {
          if (error.response.status === 406) {
            this.$toast.error("Referência já existente!");
          }
        });
    },
  },
  async mounted() {
    this.loading = true;
    this.produto = { ...this.item };
    this.produtoOriginal = { ...this.item };

    this.getProClass();
    this.img = this.produto.img;
    await this.getMarcas();
    await this.fetchFornecedores();
    if (this.produto.iva_porcentagem == 10) {
      this.produto.tipo_imposto = this.tipo_imposto.find((imposto) => {
        return imposto.value == "GRAVADO_10";
      }).value;
    } else if (this.produto.iva_porcentagem == 5) {
      this.produto.tipo_imposto = this.tipo_imposto.find((imposto) => {
        return imposto.value == "GRAVADO_5";
      }).value;
    } else {
      this.produto.tipo_imposto = this.tipo_imposto.find((imposto) => {
        return imposto.value == "ISENTO";
      }).value;
    }
    this.selectedMoeda = this.moedas.find(
      (moeda) => moeda.id_moeda === this.moeda_empresa
    );
    if (this.selectedMoeda) {
      this.produto.custo_moeda_id = this.selectedMoeda.id_moeda;
    }

    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.input-center ::v-deep input {
  text-align: center;
}

.input-right ::v-deep input {
  text-align: right;
}
</style>
